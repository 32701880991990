import {AiOutlineHome} from 'react-icons/ai'
import {CgProfile} from 'react-icons/cg'
import {GiMechanicalArm} from 'react-icons/gi'
import {AiFillAppstore} from 'react-icons/ai'
import {AiFillMessage} from 'react-icons/ai'


const data = [
    {id: 1, link: '#', icon: <AiOutlineHome/>},
    {id: 2, link: '#about', icon: <CgProfile/>},
    {id: 3, link: '#services', icon: <GiMechanicalArm/>},
    {id: 4, link: '#portfolio', icon: <AiFillAppstore/>},
    {id: 5, link: '#contact', icon: <AiFillMessage/>}
]


export default data