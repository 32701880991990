const data = [
    {
      id: 1,
      question: "What preparations are required to commence my project?",
      answer: "It depends on the nature of your project. Generally, you should have a clear idea of what you want, and we can proceed from there."
    },
    {
      id: 2,
      question: "How long will it take to finish my project?",
      answer: "The timeline will be influenced by the project's intricacy, your availability, and your payment plan. After finalizing these details, I can give you a project completion date."
    },
    {
      id: 5,
      question: "Does your pricing include domain registration and hosting?",
      answer: "No, domain registration and hosting are handled separately. You can either pay for them or take care of it yourself if you are familiar with the process."
    },
    {
      id: 4,
      question: "What is the cost for a typical website or app?",
      answer: "As previously mentioned, the cost varies depending on the project. However, my pricing is reasonable and affordable."
    },
    {
      id: 3,
      question: "What are your payment terms?",
      answer: "Payment is split into three parts. After agreeing on the project details, an upfront payment of 30% is required. 40% is due upon project completion, and the remaining 30% is payable upon delivery."
    },
    {
      id: 6,
      question: "What if the project results are unsatisfactory?",
      answer: "You will be involved in the entire project process from beginning to end, and I will do my best to ensure that the outcome meets your expectations."
    }
  ]

  export default data