import Image1 from '../../assets/project1.jpg'
import Image2 from '../../assets/project2.jpg'
import Image3 from '../../assets/project3.jpg'
import Image4 from '../../assets/project4.jpg'
import Image5 from '../../assets/project5.jpg'
import Image6 from '../../assets/project6.jpg'
import Image7 from '../../assets/project7.jpg'
import Image8 from '../../assets/project11.jpg'
import Image9 from '../../assets/project9.jpg'
import Image10 from '../../assets/project10.jpg'
import Image12 from '../../assets/Project12.jpg'
const data = [
    {
        id: 12,
        category: 'UI/UX',
        image: Image12,
        title: "Evolve 4 (UI/UX)",
        desc: "I redesigned a SaaS/ERP system used by companies like Hilton Foods, Nandos and Tesco, modernizing the UX/UI and integrating AI to optimize workflows and enhance decision-making.",
        demo: 'https://evolve4.net/',
        github: 'https://www.oddwalkstudios.com/evolve-4/'
    },
    {
        id: 10,
        category: 'UI/UX',
        image: Image10,
        title: "H+K Strategies (UI/UX)",
        desc: "For this International Advertising business I worked with data analysts to design a AI integrated dashboard system which enables Clients and Analysts to create marketing campaigns.",
        demo: 'https://www.hkstrategies.com/en/',
        github: 'https://oddwalkstudios.com/hkstrategies/'
    },
    {
        id: 7,
        category: 'UI/UX',
        image: Image7,
        title: "Adviser Plus (UI/UX)",
        desc: "The goal was to create a bolt on to the Saas packadge Empower. It is sold and distributed to some of the largest businesses in the UK such as LSE, Barclays, Iceland, Post Office, Virgin, BBC etc.",
        demo: 'https://adviserplus.com/our-solutions/empower/',
        github: 'https://www.oddwalkstudios.com/adviser-plus-2/'
    },
    {
        id: 1,
        category: 'UI/UX',
        image: Image1,
        title: "Holmes Terry (UI/UX)",
        desc: "Holmes Terry is a company which owns a block in Manchester City Centre. They needed to revamp their previous site to enable letting out their various offices, street stores and restaurants.",
        demo: 'https://holmesterry.co.uk/',
        github: 'https://oddwalkstudios.com/holmes-terry-casestudy/'
    },
    {
        id: 2,
        category: 'frontend',
        image: Image2,
        title: "Odd Walk Studios (Frontend)",
        desc: "A multi Faceted creative digital business offering various services from Web design, 3D modelling, Branding and video. I designed the entirety of the site!",
        demo: 'https://www.oddwalkstudios.com/',
        github: 'https://www.oddwalkstudios.com/'
    },
    {
        id: 3,
        category: 'frontend',
        image: Image3,
        title: "Erdy (Frontend)",
        desc: "A consolidation of a large collective of art pieces of the clients. Compiled, presented and designed from scratch from a old dataset. A site to present the artists work and history",
        demo: 'https://erdy.com/',
        github: 'https://erdy.com/'
    },
    {
        id: 4,
        category: 'UI/UX',
        image: Image4,
        title: "3D Future Design (UI/UX)",
        desc: "A 3D Rendering Business for interiors and exteriors. Displaying various elements offered as services in a clean crisp. Api connections for 3D panoramas and internal video servers",
        demo: 'http://www.3dfuturedesign.com',
        github: 'https://oddwalkstudios.com/3d-future-design-casestudy/'
    },
    {
        id: 5,
        category: 'UI/UX',
        image: Image5,
        title: "Erdwine (UI/UX)",
        desc: "An acclaimed wine business selling from the the vinyard fields of Bordeux. A eccommerce site which needed to display its identity and show its goals to distribute on a larger wholesale scale ",
        demo: 'https://erdwine.com/',
        github: 'https://erdwine.com/'
    },
    {
        id: 6,
        category: 'frontend',
        image: Image6,
        title: "Card Guard Gallery (Frontend)",
        desc: "Approached to compile his large vast database in a way that could be easily filterable and optimised to be seen internationally in the Poker community for trade and sale",
        demo: 'https://cardguardgallery.com/',
        github: 'https://oddwalkstudios.com/card-guard-casestudy/'
    },           
    {
        id: 8,
        category: 'frontend',
        image: Image8,
        title: "PAM Health (Frontend)",
        desc: "Dealing with multiple health related stores under the PAM brand. Designing banner/ Logos, organising and creating product sale avenues on shopify while integrating salesforce and other e-commerce platforms ",
        demo: 'https://66fit.co.uk/',
        github: 'https://oddwalkstudios.com/pam-health-casestudy/'
    },
    {
        id: 9,
        category: 'frontend',
        image: Image9,
        title: "Pinnacle (Frontend)",
        desc: "I showed them how to update their system, streamline, create realistic 3D renders and enhance their companies compliance with government protocols on projects all over UK.",
        demo: 'https://pinnacle-furniture.co.uk/case-studies/',
        github: 'https://oddwalkstudios.com/pinnacle-casestudy/'
    },
  
]


export default data