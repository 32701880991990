import {IoIosCreate} from 'react-icons/io'
import {BsArrowsFullscreen} from 'react-icons/bs'
import {GoServer} from 'react-icons/go'
import {AiOutlineAppstoreAdd} from 'react-icons/ai'


const data = [
    {
        id: 1, icon: <IoIosCreate/>, title: 'UX Research, Design, and Analytics', desc: "I employ a blend of qualitative and quantitative methods, including detailed user interviews, rigorous usability studies, and advanced data analysis, to inform and refine the design process, ensuring outcomes that are both user-centric and data-driven."
    },
    {
        id: 2, icon: <BsArrowsFullscreen/>, title: 'Prototyping and User Testing', desc: "Through rapid prototyping and comprehensive user testing, I quickly transform design concepts into interactive prototypes, iteratively refining them based on real user feedback to preempt usability issues and elevate the user experience."
    },
    {
        id: 3, icon: <GoServer/>, title: 'AI-Driven Design and Personalization', desc: "I integrate cutting-edge AI technologies to tailor user interfaces and personalize experiences, utilizing tools for automated usability testing and predictive analytics to create dynamic, responsive, and user-focused designs."
    },
    {
        id: 4, icon: <AiOutlineAppstoreAdd/>, title: 'Cross-Functional Collaboration and Communication', desc: 'I effectively communicate and collaborate with cross-functional teams, including stakeholders, developers, and marketers, to ensure that all project aspects align with user needs and organizational goals, enhancing project coherence and success.'
    }
]


export default data