import Avatar1 from '../../assets/avatar1.jpg'
import Avatar2 from '../../assets/avatar2.jpg'
import Avatar3 from '../../assets/avatar3.jpg'
import Avatar4 from '../../assets/avatar4.jpg'
import Avatar5 from '../../assets/avatar5.jpg'
import Avatar6 from '../../assets/avatar6.jpg'
import Avatar7 from '../../assets/avatar7.jpg'


const data = [
        {
        id: 1,
        quote: "Unlike other developers in the market, I will create an app that runs seamlessly on both IOS and Android devices without incurring any additional cost to you.",
        avatar: Avatar1,
        name: 'Eva Catherwood',
        profession: 'Marketer'
        },
        {
        id: 2,
        quote: "Great job, Richard! The project was delivered on time, and I am thrilled with the outcome. My customers also love it. It was a pleasure working with you",
        avatar: Avatar2,
        name: 'Tim Mason',
        profession: '3D Renderer'
        },
        {
        id: 3,
        quote: "It's truly amazing how accurately you executed my vision with the limited information I provided. You will be my go-to developer from now on. I highly recommend your services!",
        avatar: Avatar3,
        name: 'David Joseph',
        profession: 'Graphic Designer'
        },
        {
        id: 4,
        quote: "You have done an impressive job, my friend. I highly recommend your services!",
        avatar: Avatar4,
        name: 'Big Skull Slick',
        profession: 'Profesional Vegas Poker Player'
        },
        {
        id: 5,
        quote: "I like how the app turned out. You are incredible, Richard.",
        avatar: Avatar5,
        name: 'Ericy',
        profession: 'Artist'
        },
        {
        id: 6,
        quote: "Great job, Hajia! The project was delivered on time, and I am thrilled with the outcome. My customers also love it. It was a pleasure working with you",
        avatar: Avatar6,
        name: 'Jenessa Powell',
        profession: 'Brand Influencer'
        },
        {
        id: 7,
        quote: "I am thrilled with how the app turned out. You are a super developer",
        avatar: Avatar7,
        name: 'Emily Gavers',
        profession: 'Property Developer'
        }
    ]



    export default data