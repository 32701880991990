import {FaAward} from 'react-icons/fa'
import {GiBookshelf} from 'react-icons/gi'
import {BiHappyBeaming} from 'react-icons/bi'


const data = [
    {id: 1, icon: <FaAward/>, title: 'Experience', desc: '10+ Years Working'},
    {id: 2, icon: <GiBookshelf/>, title: 'Projects', desc: '50+ Completed'},
    {id: 3, icon: <BiHappyBeaming/>, title: 'Clients', desc: '30+ happy clients'}
    ]



    export default data;